var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "950px", height: "600px" },
    },
    [
      _c("form", { attrs: { id: "frm" } }, [
        _c(
          "button",
          {
            staticClass: "layer_close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("span", [_vm._v("close")])]
        ),
        _c(
          "div",
          { staticClass: "popup_cont" },
          [
            _c("h1", { staticClass: "page_title text_center" }, [
              _vm._v(" CONTAINER NO. CORRECTION REQUEST "),
            ]),
            _c("div", { staticClass: "content_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 1. Overview "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" Date "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(" " + _vm._s(_vm.today)),
                    ]),
                    _c("td"),
                  ]),
                  _vm._m(1),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" FROM ")]
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstEnm))]),
                    _c("td", [_vm._v(_vm._s(_vm.formData.shprCstNm))]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstTelNo,
                            expression: "formData.shprCstTelNo",
                          },
                        ],
                        attrs: {
                          id: "shprCstTelNo",
                          type: "text",
                          placeholder: "Tel",
                        },
                        domProps: { value: _vm.formData.shprCstTelNo },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formData,
                              "shprCstTelNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.shprCstEml,
                            expression: "formData.shprCstEml",
                          },
                        ],
                        attrs: {
                          id: "shprCstEml",
                          type: "text",
                          placeholder: "E-mail",
                        },
                        domProps: { value: _vm.formData.shprCstEml },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "shprCstEml",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("shprCstEml")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("shprCstEml")
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text_center", attrs: { rowspan: "2" } },
                      [_vm._v(" Booking Shipper ")]
                    ),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(_vm.formData.shprCstEnm)),
                    ]),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" 2. B/L Information "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(2),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" B/L No. "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(" " + _vm._s(_vm.formData.blNo) + " "),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" POL/POD "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(_vm.formData.polPlcNm) +
                          " / " +
                          _vm._s(_vm.formData.podPlcNm)
                      ),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_center" }, [
                      _vm._v(" VSL/VOY "),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(_vm.formData.vslNm) +
                          " / " +
                          _vm._s(_vm.formData.voyNo)
                      ),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _vm._m(3),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(4),
                _c(
                  "tbody",
                  [
                    _vm._m(5),
                    _vm._l(_vm.containerInfo, function (vo, idx) {
                      return _c("tr", { key: "cntr_" + idx }, [
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(" Container No. "),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.bfCntrNo,
                                expression: "vo.bfCntrNo",
                              },
                            ],
                            staticStyle: { width: "200px" },
                            attrs: {
                              id: "bfCntrNo_" + idx,
                              type: "text",
                              maxlength: "11",
                            },
                            domProps: { value: vo.bfCntrNo },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "bfCntrNo", $event.target.value)
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    vo,
                                    "bfCntrNo",
                                    idx
                                  )
                                },
                              ],
                              blur: function ($event) {
                                return _vm.checkValidationBfCntrNo(idx)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.updtCntrNo,
                                expression: "vo.updtCntrNo",
                              },
                            ],
                            staticStyle: { width: "200px" },
                            attrs: {
                              id: "updtCntrNo_" + idx,
                              type: "text",
                              maxlength: "11",
                            },
                            domProps: { value: vo.updtCntrNo },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    vo,
                                    "updtCntrNo",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    vo,
                                    "updtCntrNo",
                                    idx
                                  )
                                },
                              ],
                              blur: function ($event) {
                                return _vm.checkValidationAfCntrNo(idx)
                              },
                            },
                          }),
                          idx === 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addBLContainer(idx)
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              )
                            : _vm._e(),
                          idx !== 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  staticStyle: { "margin-left": "5px" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeBLContainer(idx)
                                    },
                                  },
                                },
                                [_vm._v(" minus ")]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "text_center mt10" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cntrNoCorrectReqInsert()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.062")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reqTempSaveYn === "Y",
                      expression: "reqTempSaveYn === 'Y'",
                    },
                  ],
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.cntrNoCorrectReqDelete()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("art.CMBA660.007")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.reqCntrNoCorrSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("js.booking.044")))]
              ),
              _vm._v("  "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("close")()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX010P095.057")))]
              ),
            ]),
            _c(
              "win-layer-pop",
              { staticClass: "popup_dim" },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.uploadParam },
                      on: {
                        close: _vm.closePopup,
                        "set-dext-file-info": _vm.arrangeUploadParam,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text_center" }, [_vm._v(" To ")]),
      _c("td", { attrs: { colspan: "2" } }, [_vm._v("고려해운(주)")]),
      _c("td"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "content_title" }, [
      _vm._v(" 3. Request Details"),
      _c("br"),
      _c("br"),
      _vm._v(" We hearby request you for the under mentioned corrections. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
      _c("col", { staticStyle: { width: "40%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text_center" }),
      _c("th", { staticClass: "text_center" }, [_vm._v("NOW READ (FROM)")]),
      _c("th", { staticClass: "text_center" }, [_vm._v("SHOULD BE READ (TO)")]),
      _c("th"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup_cont cod_cont_area" },
            [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P100.001")) + " "),
                _c(
                  "span",
                  { staticClass: "position_relative screen-to-pdf-hide" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon help",
                        on: {
                          mouseover: function ($event) {
                            return _vm.showToolTip()
                          },
                          mouseleave: function ($event) {
                            return _vm.showToolTip()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.057")))]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.toolTipActive,
                            expression: "toolTipActive",
                          },
                        ],
                        staticClass: "tooltip_wrap short position_absolute",
                        staticStyle: { width: "340px", left: "0", top: "28px" },
                      },
                      [
                        _c("div", { staticClass: "cont" }, [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.001"))),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm t2" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ONEX010P100.002")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm.delegateYn === "Y"
                ? _c("div", { staticClass: "content_box" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(0),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.003"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      _vm.nowDate,
                                      "."
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.004"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.005"))),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.006"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                              ),
                            ]),
                            _c("div", { staticClass: "tbl_form mt5" }, [
                              _c("div", [
                                _c("span", { staticClass: "dv col_1" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicNm)),
                                ]),
                                _c("span", { staticClass: "dv col_2" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicTelNo)),
                                ]),
                                _c("span", { staticClass: "dv col_3" }, [
                                  _vm._v(_vm._s(_vm.formData.reqPicEmlAddr)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.007"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.bookingInfo.actShprCstEnm) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.delegateYn === "Y"
                ? _c("div", { staticClass: "content_box mt10 cod_cont_area" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(1),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.008"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.blNo) + " "),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.009"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.010"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.polPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.podPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.011"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.vslNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.voyNo) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("p", { staticClass: "mt10" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.014"))),
                    ]),
                    _c("table", { staticClass: "tbl_col mt10" }, [
                      _vm._m(2),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("th"),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.015"))),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.016"))),
                            ]),
                            _c("th"),
                          ]),
                          _vm._l(
                            _vm.formData.containerList,
                            function (vo, idx) {
                              return _c(
                                "tr",
                                {
                                  key: "cntr_" + idx,
                                  staticClass: "screen-to-pdf-hide",
                                },
                                [
                                  _c("th", { staticClass: "text_left" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.ONEX010P100.017"))
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tbl_form div_cntr_area",
                                        attrs: { id: "alertArea_" + idx },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "dv col_4" },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: vo.rmk,
                                                    expression: "vo.rmk",
                                                  },
                                                ],
                                                attrs: { id: "rmk_" + idx },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        vo,
                                                        "rmk",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function ($event) {
                                                      ;[
                                                        _vm.setCntrInfo(idx),
                                                        _vm.checkValidationCntrNoFrom(
                                                          idx
                                                        ),
                                                      ]
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("선택")]
                                                ),
                                                _vm._l(
                                                  _vm.formData.cntrNoList,
                                                  function (option) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: option.cntrNo,
                                                        domProps: {
                                                          value: option.cntrNo,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.cntrNo
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.cntrNo,
                                          expression: "vo.cntrNo",
                                        },
                                      ],
                                      staticClass: "dv",
                                      attrs: {
                                        type: "text",
                                        id: "cntrNo_" + idx,
                                        placeholder: "변경 후 컨테이너 No.",
                                      },
                                      domProps: { value: vo.cntrNo },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              vo,
                                              "cntrNo",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.checkValidationCntrNoTo(
                                              idx
                                            )
                                          },
                                        ],
                                        blur: function ($event) {
                                          return _vm.containerChk(idx)
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("td", [
                                    _c("span", { staticClass: "dv" }, [
                                      idx === 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "tbl_icon plus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addContainer()
                                                },
                                              },
                                            },
                                            [_vm._v(" plus ")]
                                          )
                                        : _vm._e(),
                                      idx !== 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "tbl_icon minus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeContainer(
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" minus ")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          _vm._l(
                            _vm.formData.containerList,
                            function (vo, idx) {
                              return _c(
                                "tr",
                                {
                                  key: "cntr_" + idx,
                                  staticClass: "screen-to-pdf-show",
                                },
                                [
                                  _c("th", { staticClass: "text_left" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("msg.ONEX010P100.017"))
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tbl_form div_cntr_area",
                                        attrs: { id: "alertArea_" + idx },
                                      },
                                      [
                                        _c("p", [
                                          _c(
                                            "span",
                                            { staticClass: "dv col_4" },
                                            [_vm._v(" " + _vm._s(vo.rmk))]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c("p", [
                                      _c("span", { staticClass: "dv" }, [
                                        _vm._v(" " + _vm._s(vo.cntrNo)),
                                      ]),
                                    ]),
                                  ]),
                                  _c("td"),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "normal_box" }, [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P100.018")) + " "
                        ),
                      ]),
                      _c("p", { staticClass: "text_center" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.changeDatePattern(_vm.nowDate, ".")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _c("span", { staticClass: "screen-to-pdf-hide" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agreeYn,
                                expression: "agreeYn",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "no",
                              id: "chk1",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.agreeYn)
                                ? _vm._i(_vm.agreeYn, null) > -1
                                : _vm._q(_vm.agreeYn, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.agreeYn,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.agreeYn = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.agreeYn = $$c
                                }
                              },
                            },
                          }),
                          _vm._m(3),
                        ]),
                        _vm._m(4),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P100.019")) + " ("
                        ),
                        _c("span", { staticClass: "ml10 mr10" }, [
                          _vm._v(_vm._s(_vm.bookingInfo.actShprCstEnm)),
                        ]),
                        _vm._v(
                          ")" + _vm._s(_vm.$t("msg.ONEX010P100.020")) + " "
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "txt_desc mt10",
                          staticStyle: { background: "none" },
                        },
                        [
                          _c("span", { staticClass: "font_medium" }, [
                            _vm._v(
                              "[" + _vm._s(_vm.$t("msg.ONEX010P100.021")) + "]"
                            ),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P100.022")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX010P100.023")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX010P100.024"))
                          ),
                          _c("br"),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.delegateYn === "N"
                ? _c("div", { staticClass: "content_box" }, [
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX010P100.026")) + " "
                          ),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "mt5" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.memberDetail.userCtrCd !== "KR",
                              expression: "memberDetail.userCtrCd !== 'KR'",
                            },
                          ],
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.excelDownload(
                                    "ContainerChgNumRequest.xlsx"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.027")))]
                          ),
                        ]
                      ),
                      !(
                        _vm.memberDetail.userCtrCd === "KR" &&
                        _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                      )
                        ? _c("span", { staticClass: "ml5" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button sm",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.moveDelegateReqPage()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.028")))]
                            ),
                          ])
                        : _vm._e(),
                      !(
                        _vm.memberDetail.userCtrCd === "KR" &&
                        _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                      )
                        ? _c("span", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon help ml10",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.guidePop()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.029")))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.030"))),
                    ]),
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(5),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P100.031"))),
                          ]),
                          _c("td", [
                            _vm.memberDetail.userCtrCd == "KR"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "filebox",
                                    attrs: { id: "file_att" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button sm gray file",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openEleConsReqDocPop()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("js.booking.043")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "filebox",
                                    attrs: { id: "file_att" },
                                  },
                                  [
                                    _vm.uploadParam.length === 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "button sm gray file",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.uploadFilePop()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("msg.ONEX010P100.032")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.uploadParam.length > 0
                                      ? _c("div", [
                                          _c("span", {
                                            staticClass: "tbl_icon attach",
                                          }),
                                          _c(
                                            "a",
                                            { staticClass: "text_link" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.uploadParam[0]
                                                    .originalName
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "tbl_icon attdel",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeUploadFile()
                                                },
                                              },
                                            },
                                            [_vm._v("X")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                          ]),
                          _c("td", { staticClass: "border_left_none" }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "txt_desc mt10",
                        staticStyle: { background: "none" },
                      },
                      [
                        _c("span", { staticClass: "font_medium" }, [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.ONEX010P100.021")) + "]"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX010P100.022")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX010P100.023")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX010P100.024"))
                        ),
                        _c("br"),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt10 text_right screen-to-pdf-hide" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.memberDetail.userCtrCd !== "KR",
                        expression: "memberDetail.userCtrCd !== 'KR'",
                      },
                    ],
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.reqSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.031")))]
                ),
                _vm.delegateYn === "Y"
                  ? _c(
                      "a",
                      {
                        staticClass: "button gray lg",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.036")))]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "button gray lg",
                        on: {
                          click: function ($event) {
                            return _vm.confirmClose()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX010P100.036")))]
                    ),
              ]),
              _vm.delegateYn === "Y"
                ? _c(
                    "win-layer-pop",
                    { staticClass: "popup_dim" },
                    [
                      _vm.customComponent
                        ? _c(_vm.customComponent, {
                            tag: "component",
                            attrs: { "parent-info": _vm.popParams },
                            on: { 1: _vm.selectFunc, close: _vm.closePopup },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.delegateYn === "N"
                ? [
                    _vm.memberDetail.userCtrCd == "KR"
                      ? _c(
                          "win-layer-pop",
                          { ref: "openPop", staticClass: "popup_dim_snd" },
                          [
                            _vm.popComponent
                              ? _c(_vm.popComponent, {
                                  tag: "component",
                                  attrs: { "parent-info": _vm.popParams },
                                  on: {
                                    close: _vm.closeCommonPopup,
                                    callback: _vm.popCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "win-layer-pop",
                          { ref: "uploadPop", staticClass: "popup_dim" },
                          [
                            _vm.customComponent
                              ? _c(_vm.customComponent, {
                                  tag: "component",
                                  attrs: {
                                    "parent-info": _vm.uploadParam,
                                    "single-file-yn": true,
                                  },
                                  on: {
                                    "set-dext-file-info":
                                      _vm.arrangeUploadParam,
                                    close: _vm.closePopup,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm.delegateYn === "N"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      attrs: { id: "guide_pop" },
                    },
                    [
                      _vm.customComponent
                        ? _c(_vm.customComponent, {
                            tag: "component",
                            on: { close: _vm.closePopup },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
      _c("col"),
      _c("col"),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk1" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "screen-to-pdf-show" }, [
      _c("input", {
        attrs: {
          checked: "checked",
          disabled: "disabled",
          type: "checkbox",
          name: "no",
          id: "chk1_temp",
        },
      }),
      _c("label", { attrs: { for: "chk1_temp" } }, [
        _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="popup_wrap doc_req_pop" style="width:900px; height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont cod_cont_area">
        <!-- popup_cont -->
        <h1 class="page_title">
          <!-- CONTAINER NO. CORRECTION REQUEST -->
          {{ $t('msg.ONEX010P100.001') }}
          <span class="position_relative screen-to-pdf-hide">
            <button class="tbl_icon help" @mouseover="showToolTip()" @mouseleave="showToolTip()">{{ $t('msg.ONEX010P100.057') }}</button>
            <div v-show="toolTipActive" class="tooltip_wrap short position_absolute" style="width:340px; left:0; top:28px;"><!-- tooltip_wrap   -->
              <div class="cont"><!-- cont -->
                <p class="title">{{ $t('msg.ONEX010P100.001') }}</p><!-- CONTAINER NO. CORRECTION REQUEST -->
                <ul class="bul_list_sm t2">
                  <li>
                    {{ $t('msg.ONEX010P100.002') }}
                  </li>
                </ul>
              </div><!-- cont -->
            </div><!-- tooltip_wrap // -->
          </span>
        </h1>
        <div
          v-if="delegateYn === 'Y'"
          class="content_box"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX010P100.003') }}</th><!-- DATE -->
                <td>
                  <div class="tbl_form">
                    {{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.004') }}</th><!-- TO -->
                <td>{{ $t('msg.ONEX010P100.005') }}</td><!-- KOREA MARINE TRANSPORT CO., LTD. -->
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.006') }}</th><!-- FROM -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.shprCstNm }}
                  </div>
                  <div class="tbl_form mt5">
                    <div>
                      <span class="dv col_1">{{ formData.reqPicNm }}</span>
                      <span class="dv col_2">{{ formData.reqPicTelNo }}</span>
                      <span class="dv col_3">{{ formData.reqPicEmlAddr }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.007') }}</th><!-- Booking Shipper -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.actShprCstEnm }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'Y'"
          class="content_box mt10 cod_cont_area"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX010P100.008') }}</th><!-- Bl No. -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.blNo }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.009') }}</th><!-- Bl Shipper -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.shprCstNm }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.010') }}</th><!-- Port of Loading/Discharge -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.polPlcNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.podPlcNm }}
                    </span>
                    <span class="dv col_4">
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX010P100.011') }}</th><!-- Vessel/Voyage -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.vslNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.voyNo }}
                    </span>
                    <span class="dv col_4">

                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="mt10">{{ $t('msg.ONEX010P100.014') }}</p><!-- We hereby request you for the under mentioned corrections. -->

          <table class="tbl_col mt10">
            <colgroup>
              <col style="width:200px">
              <col>
              <col>
              <col>
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th></th>
                <th>{{ $t('msg.ONEX010P100.015') }}</th><!-- NOW READ (FROM) -->
                <th>{{ $t('msg.ONEX010P100.016') }}</th><!-- HOULD BE READ (TO) -->
                <th></th>
              </tr>
              <tr
                class="screen-to-pdf-hide"
                v-for="(vo, idx) in formData.containerList"
                :key="'cntr_' + idx"
              >
                <th class="text_left">{{ $t('msg.ONEX010P100.017') }}</th><!-- CONTENTS -->
                <td>
                  <div
                    class="tbl_form div_cntr_area"
                    :id="'alertArea_' + idx"
                  >
                    <span class="dv col_4">
                      <select
                        v-model="vo.rmk"
                        :id="'rmk_' + idx"
                        @change="[setCntrInfo(idx),checkValidationCntrNoFrom(idx)]"
                      >
                        <option value="">선택</option>
                        <option
                          v-for="option in formData.cntrNoList"
                          :key="option.cntrNo"
                          :value="option.cntrNo"
                        >
                          {{ option.cntrNo }}
                        </option>
                      </select>
                    </span>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="dv"
                    v-model="vo.cntrNo"
                    :id="'cntrNo_' + idx"
                    placeholder="변경 후 컨테이너 No."
                    @input="checkValidationCntrNoTo(idx);"
                    @blur="containerChk(idx)"
                  >
                </td>
                <td>
                  <span class="dv">
                    <button
                      v-if="idx === 0"
                      class="tbl_icon plus"
                      @click="addContainer()"
                    >
                      plus
                    </button>
                    <button
                      v-if="idx !== 0"
                      class="tbl_icon minus"
                      @click="removeContainer(idx)"
                    >
                      minus
                    </button>
                  </span>
                </td>
              </tr>
              <tr
                class="screen-to-pdf-show"
                v-for="(vo, idx) in formData.containerList"
                :key="'cntr_' + idx"
              >
                <th class="text_left">{{ $t('msg.ONEX010P100.017') }}</th><!-- CONTENTS -->
                <td>
                  <div
                    class="tbl_form div_cntr_area"
                    :id="'alertArea_' + idx"
                  >
                    <p>
                      <span class="dv col_4"> {{ vo.rmk }}</span>
                    </p>
                  </div>
                </td>
                <td>
                  <p>
                    <span class="dv"> {{ vo.cntrNo }}</span>
                  </p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <div class="normal_box">
            <p>
              {{ $t('msg.ONEX010P100.018') }}
            </p>
            <p class="text_center">{{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}</p>
            <p class="text_center mt10">
              <span class="screen-to-pdf-hide">
                <input
                  v-model="agreeYn"
                  type="checkbox"
                  name="no"
                  id="chk1"
                  true-value="Y"
                  false-value="N"
                >
                <label for="chk1"><span class="offscreen">No</span></label>
              </span>
              <span class="screen-to-pdf-show">
                <input
                  checked="checked"
                  disabled="disabled"
                  type="checkbox"
                  name="no"
                  id="chk1_temp"
                >
                <label for="chk1_temp"><span class="offscreen">No</span></label>
              </span>
              {{ $t('msg.ONEX010P100.019') }} (<span class="ml10 mr10">{{ bookingInfo.actShprCstEnm }}</span>){{ $t('msg.ONEX010P100.020') }}
            </p>
            <p class="txt_desc mt10" style="background: none;">
              <span class="font_medium">[{{ $t('msg.ONEX010P100.021') }}]</span>
              {{ $t('msg.ONEX010P100.022') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX010P100.023') }} : {{ $t('msg.ONEX010P100.024') }}<br>
            </p>
          </div>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'N'"
          class="content_box"
        >
          <!-- content_box -->
          <p v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')">
            {{ $t('msg.ONEX010P100.026') }}
          </p>
          <p class="mt5">
            <span v-show="memberDetail.userCtrCd !== 'KR'"><a class="button sm" href="#" @click.prevent="excelDownload('ContainerChgNumRequest.xlsx')">{{ $t('msg.ONEX010P100.027') }}</a></span><!-- 컨테이너 번호변경 요청서 양식 다운 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')" class="ml5"><a class="button sm" href="#" @click.prevent="moveDelegateReqPage()">{{ $t('msg.ONEX010P100.028') }}</a></span><!-- 위수임 신청 바로가기 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')">
              <button type="button" class="tbl_icon help ml10" @click.prevent="guidePop()">{{ $t('msg.ONEX010P100.029') }}</button><!-- 도움말 -->
            </span>
          </p>

          <h2 class="content_title">{{ $t('msg.ONEX010P100.030') }}</h2><!-- 컨테이너 번호변경 요청서 제출 -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX010P100.031') }}</th><!-- 제출 -->
                <td>
                  <div v-if="memberDetail.userCtrCd == 'KR'" class="filebox" id="file_att">
                    <a
                      class="button sm gray file"
                      @click.prevent="openEleConsReqDocPop()"
                    >
                      {{ $t('js.booking.043') }} <!-- 양식작성 -->
                    </a>
                  </div>
                  <div v-else class="filebox" id="file_att">
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX010P100.032') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a
                        class="tbl_icon attdel"
                        @click="removeUploadFile()"
                      >X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                  <!--<a class="button sm" href="#">제출</a>-->
                </td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc mt10" style="background: none;">
            <span class="font_medium">[{{ $t('msg.ONEX010P100.021') }}]</span>
            {{ $t('msg.ONEX010P100.022') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX010P100.023') }} : {{ $t('msg.ONEX010P100.024') }}<br>
          </p>
        </div><!-- content_box // -->

        <div class="mt10 text_right screen-to-pdf-hide">
          <a v-show="memberDetail.userCtrCd !== 'KR'" class="button blue lg mr5" @click="reqSave()">{{ $t('msg.ONEX010P100.031') }}</a>
          <a v-if="delegateYn === 'Y'" class="button gray lg" @click="$emit('close')">{{ $t('msg.ONEX010P100.036') }}</a>
          <a v-else class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX010P100.036') }}</a>
        </div>

        <win-layer-pop v-if="delegateYn === 'Y'" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="popParams"
            @close="closePopup"
            @1="selectFunc"
          />
        </win-layer-pop>

        <template v-if="delegateYn === 'N'">
          <win-layer-pop v-if="memberDetail.userCtrCd == 'KR'" ref="openPop" class="popup_dim_snd">
            <component
              :is="popComponent"
              v-if="popComponent"
              :parent-info="popParams"
              @close="closeCommonPopup"
              @callback="popCallback"
            />
          </win-layer-pop>

          <win-layer-pop v-else ref="uploadPop" class="popup_dim">
            <component
              :is="customComponent"
              v-if="customComponent"
              :parent-info="uploadParam"
              :single-file-yn="true"
              @set-dext-file-info="arrangeUploadParam"
              @close="closePopup"
            />
          </win-layer-pop>
        </template>

        <div v-show="false" id="guide_pop" v-if="delegateYn === 'N'">
          <component
            :is="customComponent"
            v-if="customComponent"
            @close="closePopup"
          />
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerGuidePop from '@/pages/trans/popup/ContainerGuidePop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'
import EleConsReqDocPop from '@/pages/trans/popup/eleConsReqDocPop'
import CntrNoCorrectReqInputPop from '@/pages/trans/popup/CntrNoCorrectReqInputPop'

export default {
  name: 'CntrNoCorrectReqPop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    ContainerGuidePop,
    EleConsReqDocPop,
    CntrNoCorrectReqInputPop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      delegateYn: '',
      ops: {
        scrollPanel: {
          speed: 300
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: '#c1c1c1',
          opacity: 1
        }
      },
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      agreeYn: 'N',
      picNmh: '',
      toolTipActive: false,
      cstInfoList: [],
      cntrList: [],
      selectFunc: null,
      customComponent: null,
      popParams: {},
      uploadParam: [],
      formData: {
        entPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqRsnCont: '',
        //Bl No.에 묶여진 컨테이너 리스트
        cntrNoList: [],
        containerList: [
          {
            rmk: '',
            cntrNo: ''
          }
        ],
        uploadFileInfos: []
      },
      excelData: [
        {
          sample: 'Sample'
        }
      ],
      popComponent: null
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.blNo)) {
        this.setDelegateYn(this.$props.parentInfo.blNo)
      }
    }
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      await this.setDelegateYn(parentInfo.blNo)

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
        } else {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      const arrPromise = [
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        commons.getCstInfo().then(res => {
          this.cstInfoList = res
        })
      ]

      arrPromise.push(
        docs.getReqDocBookingBl(parentInfo.blNo).then(async res => {
          const result = res.data
          await TH.setBookingInfo(result)
        })
      )

      await Promise.all(arrPromise)
    },
    async setDelegateYn (blNo) {
      // CD_ID = 01007 (30: 수출 ALL, 33: 컨테이번번호변경요청)
      await transCommon.getBlDelegateYn({ blNo: blNo, arrCstGrpCatCd: ['30', '33'] }).then(async (res) => {
        if (res.data === 'Y') {
          this.delegateYn = 'N'
        } else {
          this.delegateYn = 'N'
        }
      })
    },
    async setBookingInfo (result) {
      const TH = this
      this.bookingInfo = result

      if (this.$ekmtcCommon.isNotEmpty(this.bookingInfo.blNo)) {
        const blNo = this.bookingInfo.blNo

        await docs.getBkgContainerInfo(blNo).then(res => {
          const cntrList = res.data

          console.log('###### getBkgContainerInfo ######')
          console.log(cntrList)

          if (cntrList !== undefined && cntrList.length > 0) {
            cntrList.forEach(c => {
              const cdInfo = TH.cntrTypeList.filter(vo => vo.cd === c.cntrTypCd)

              if (cdInfo !== undefined && cdInfo.length > 0) {
                c.cntrTypNm = cdInfo[0].cdNm
              }
            })

            TH.formData.cntrNoList = cntrList
          }
        })
      }
    },
    addContainer (flag) {
      const defInfo = {
        rmk: '',
        cntrNo: ''
      }

      const size = this.formData.containerList.length

      if (flag !== 'init') {
        if (this.formData.cntrNoList.length - 1 < size) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P100.037'))
        } else {
          this.formData.containerList.push(defInfo)
        }
      }
    },
    removeContainer (idx) {
      const containerList = this.formData.containerList
      containerList.splice(idx, 1)

      const containerLen = containerList.length
      if (containerLen === 0) {
        this.addContainer()
      }
    },
    async setCntrInfo (idx, flag) {
      console.log('####### setCntrInfo #######')
      const cntrNo = this.formData.containerList[idx].cntrNo

      if (cntrNo !== '' && cntrNo !== undefined) {
        console.log('#### 컨테이너 넘버가 바뀔때 체크 ####')
      }
    },
    checkValidationCntrAll () {
      let isOk = true
      const containerList = this.formData.containerList

      for (let idx = 0; idx < containerList.length; idx++) {
        if (!this.checkValidationCntrNoFrom(idx)) {
          isOk = false
        }
        if (!this.checkValidationCntrNoTo(idx)) {
          isOk = false
        }
      }

      return isOk
    },
    //NOW READ 밸리데이션
    checkValidationCntrNoFrom (idx) {
      let isOk = true
      const frm = document.querySelector('#frm_req_pop')
      const selector = frm.querySelector('#rmk_' + idx)
      const selector2 = frm.querySelector('#alertArea_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector2)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector2, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    },
    //
    checkValidationCntrNoTo (idx) {
      let isOk = true
      const frm = document.querySelector('#frm_req_pop')
      const selector = frm.querySelector('#cntrNo_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    },
    //컨테이너 넘버 체크 및 중복 체크
    containerChk (idx) {
      const containerList = this.formData.containerList
      const cntrNo = containerList[idx].cntrNo.toUpperCase()
      const cntrNoList = this.formData.cntrNoList
      const shprVanYn = cntrNoList[idx].shprVanYn

      //화주소유컨테이너(SOC)인 경우 컨테이너 넘버체크 X
      if (shprVanYn === 'Y') return

      if (cntrNo.length > 10) {
        docs.containerChk(cntrNo)
          .then(response => {
            if (response.data === 'N') {
              this.$ekmtcCommon.alertCallback(this.$t('art.CMATK363'), function () {
                containerList[idx].cntrNo = ''
              })
            }
        })
      } else {
        this.$ekmtcCommon.alertCallback(this.$t('art.CMATK363'), function () {
          containerList[idx].cntrNo = ''
        })
      }
    },
    fnChkCntrNo (idx) {
      const containerList = this.formData.containerList
      const cntrNo = containerList[idx].cntrNo
      if (cntrNo.length === 0) return

      let isOk = true
      containerList.forEach((c, i) => {
        if (i !== idx && this.$ekmtcCommon.isNotEmpty(c.cntrNo) && c.cntrNo.trim() === cntrNo.trim()) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBK100.247'), function () {
            containerList[idx].cntrNo = ''
          })
          isOk = false
        }
      })

      if (!isOk) {
        return
      }

      const frm = document.querySelector('#frm_req_pop')
      const selector = frm.querySelector('#cntrNo_' + idx)
      let success = true

      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (cntrNo.length > 10) {
        transCommon.findCntrInfo(cntrNo).then(res => {
          const result = res.data

          if (result !== undefined) {
            const chkYn = result.chkYn

            if (chkYn === 'Y') {
              const cntrTypCd = result.cntrTypCd
              const cdInfo = this.cntrTypeList.filter(vo => vo.cd === cntrTypCd)

              if (cdInfo !== undefined && cdInfo.length > 0) {
                containerList[idx].cntrTypNm = cdInfo[0].cdNm
              }
            } else {
              success = false
            }
          }
        })
      } else {
        success = false
      }

      if (!success) {
        this.$ekmtcCommon.alertCallback(this.$t('art.CMATK363'), function () {
          containerList[idx].cntrNo = ''
        })
      }
    },
    async reqSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      if (TH.delegateYn === 'Y') {
        if (!TH.checkValidationCntrAll()) {
          isOk = false
        }
      }

      if (TH.delegateYn === 'N') {
        formData.uploadFileInfos = this.uploadParam.map((item) => {
          if (item.new !== true) {
            item.fileId = item.asFileId
          }
          return item
        })

        TH.$delete(formData, 'containerList')

        if (TH.delegateYn === 'N' && formData.uploadFileInfos.length === 0) {
          const attachSelector = document.querySelector('#file_att')
          TH.$ekmtcCommon.showErrorTooltip(attachSelector, TH.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (isOk) {
        if (TH.agreeYn !== 'Y' && TH.delegateYn === 'Y') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX010P100.025'))
          return
        }

        let successFlag = true

        formData.vslCd = TH.bookingInfo.vslCd
        formData.voyNo = TH.bookingInfo.voyNo
        formData.polCtrCd = TH.bookingInfo.polCtrCd
        formData.polPortCd = TH.bookingInfo.polPortCd
        formData.podCtrCd = TH.bookingInfo.podCtrCd
        formData.podPortCd = TH.bookingInfo.podPortCd
        formData.shprCstNo = TH.bookingInfo.shprCstNo
        formData.shprCstNm = TH.bookingInfo.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.bookingInfo.bkgNo
        formData.reqCatCd = '17'

        if (this.delegateYn === 'Y') {
          const frm = document.querySelector('#frm_req_pop')
          const ele = frm.querySelector('.cod_cont_area')
          this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

          await this.$ekmtcCommon.screenToPdf({ cssSelector: '.cod_cont_area' })
            .then(async (res) => {
              const reqData = new FormData()
              const fileName = this.$t('msg.ONEX010P100.001') + '.pdf'
              reqData.append('files', res.output('blob'), fileName)

              await commons.tempFileUpload(reqData).then((result) => {
                  console.log(result)
                  const fileId = result.data.fileId

                  const fileObj = {
                    fileId: fileId,
                    originalName: fileName
                  }

                  formData.uploadFileInfos.push(fileObj)
                })
                .catch(err => {
                  console.log(err)
                })

                TH.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
            })
        }
        let result = ''
        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }
          result = res.data
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag && result !== null) {
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
              TH.$emit('selectFunc', result)
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    guidePop () {
      this.openPopup('ContainerGuidePop')
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closePopup (compNm) {
      const elemGuidPopup = document.querySelector('#guide_pop')
      this.customComponent = null
      this.selectFunc = null

      if (compNm !== 'confirmClose') {
        elemGuidPopup.style.display = 'none'
      } else {
        this.$emit('close')
      }

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    showToolTip () {
      console.log(this.toolTipActive)
      this.toolTipActive = !this.toolTipActive
    },
    async moveDelegateReqPage () {
      const TH = this
      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    async confirmClose () {
      //if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX020P130.062'), useConfirmBtn: true })) {
        this.$emit('close')
      //}
    },
    async excelDownload (fileName) {
      console.log('#### excel Download ####')

      const data = {
        fileNm: fileName,
        blNo: this.parentInfo.blNo
      }

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
        //using blob download
        const result = await docs.getExcelDownload(data)
        console.log('##### excelDownload #####')
        console.log(result)
        //blob 객체 생성
        const blob = new Blob([result.data], { type: result.headers['content-type'] })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //IE에서 동작
          window.navigator.msSaveOrOpenBlob(result.data, fileName)
        } else {
          console.log(result.data)
          //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.target = '_self'
          link.download = fileName
          link.click()

          //url 사용 후 메모리에서 제거
          window.URL.revokeObjectURL(url)
        }
      }
    },
    openCommonPopup (compNm) {
      this.popComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim_snd')
    },
    closeCommonPopup (compNm) {
      this.popComponent = null

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim_snd')
    },
    openEleConsReqDocPop () {
      this.popParams.reqCatCd = '17'
      this.popParams.bkgNo = this.parentInfo.bkgNo
      this.popParams.blNo = this.parentInfo.blNo

      this.openCommonPopup('EleConsReqDocPop')
    },
    popCallback (callParams) {
      console.log('popCallback @@@@@ ', callParams)

      this.openCommonPopup('CntrNoCorrectReqInputPop')
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
